






























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getImagePreview } from '@/utils/blogPost'
import {
  deleteBlogPost,
  deleteBlogPostBulk,
  getBlogPosts
} from '@/api/blogPosts'
import {
  CollectionRequestMeta,
  createFilter,
  getSortItems,
  prepareListFilters
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import {
  debounce,
  parseTime,
  hasPermission,
  confirmDialog,
  successMsg,
  errorMsg
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'

const searchFields = [
  {
    key: 'translations.title'
  }
]

@Component({
  name: 'BlogPostList',
  components: {
    Pagination,
    ListToolbar
  }
})

export default class extends Vue {
  private total = 0
  private list = []
  private listLoading = true
  private search = ''
  private selected: any = []
  private listQuery: CollectionRequestMeta = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private hasPermission = hasPermission
  private getImagePreview = getImagePreview
  created() {
    this.getList()
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteBlogPost({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected.map((item: any) => {
      return item.id
    })
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/blog/edit/' + id)
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deleteBlogPostBulk(this.selected)
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareFilters() {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: searchFields
    })
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters()
      const { data } = await getBlogPosts(this.listQuery)
      this.list = data.collection
      this.total = data.pagination.total_items
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
    this.debounceMethod()
  }
}
